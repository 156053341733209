import React, { useEffect, } from 'react';
import { difference, uniq, sortBy, omit, isEmpty } from 'lodash';
import { format as formatDate, } from 'date-fns';
import { useLocation, useHistory, } from 'react-router';
import { Button } from 'reactstrap';
import numeral from 'numeral';
import classnames from 'classnames';

import firebase from '../firebase';
import { fields as screenQueryFields, } from '../shared/models/screenQuery';
import { fullPathWithParams, } from '../utils';
import useQueryParams from './hooks/useQueryParams';
import useCollectionSubscription from './hooks/useCollectionSubscription';
import AddButton from './AddButton';
import EditButton from './EditButton';
import DeleteButton from './DeleteButton';
import ProgressButton from './ProgressButton';
import ModelFormModal from './modals/ModelFormModal';
import QuerySelector from './QuerySelector';
import QueryInput from './QueryInput';
import QueryBoolean from './QueryBoolean';
import NumberInput from './NumberInput';
import ModalButton from './ModalButton';

const db = firebase.firestore();

export default function TrialQueriesSection (props) {
  const { company, screenType, items, screenQueries, readOnly = false, uncontrols = [], } = props;
  const queryParams = useQueryParams();
  const valuedItems = items.map(_ => _.map(_ => ({ ..._, value: queryParams[_.name] })));
  const location = useLocation();
  const history = useHistory();
  const apply = (screenQuery) => {
    const { name, queryParams, extralControls, } = screenQuery;
    const path = fullPathWithParams({
      ...items.flatMap(_ => _.map(_ => _.name)).filter(_ => !uncontrols.includes(_)).reduce((x, y) => ({ ...x, [y]: '' }), {}),
      ...omit(queryParams, [...difference(uncontrols, extralControls), 'period', 'targetMonth', 'documentType', 'tabKey', 'commenteeKey']),
    } , location);
    history.replace(encodeURI(path));
  };
  const defaultScreenQueries = useCollectionSubscription(company.ref?.collection?.('screenQueries').where('screenType', '==', screenType).where('isDefault', '==', true), [company, screenType, readOnly], { initialValue: null });
  useEffect(() => {
    if(defaultScreenQueries != null) {
      const defaultScreenQuery = defaultScreenQueries.find(_ => _.isDefault);
      defaultScreenQuery && apply(defaultScreenQuery);
    }
  }, [defaultScreenQueries != null]);

  return (
    <div className="d-flex flex-wrap gap-1 align-items-end">
      {
        valuedItems.flat().filter(_ => _.isFront || (!isEmpty(_.value))).map((item) => {
          return (
            <Item key={item.name} item={item} />
          );
        })
      }
      <ModalButton title="表示条件" color="link" size="sm" content={_ => <TrialQueriesModalContent apply={apply} company={company} screenType={screenType} items={valuedItems} screenQueries={screenQueries} readOnly={readOnly} uncontrols={uncontrols} />} modalProps={{ style: { minWidth: 900 } }}>
        <span className="fas fa-plus mr-1" />
        表示条件
      </ModalButton>
    </div>
  );
};

function Item(props) {
  const { item, } = props;
  const { name, label, type, options, props: itemProps, isFront = false, value, } = item;
  const { Component, typeProps }= ({
    select: {
      Component: QuerySelector,
    },
    multiSelect: {
      Component: QuerySelector,
      typeProps: { isMulti: true },
    },
    numeric: {
      Component: QueryInput,
      typeProps: {
        inputClassName: 'text-right',
        type: 'float',
      },
    },
    boolean: {
      Component: QueryBoolean,
    },
  })[type];

  return (
    <Component
      paramName={name}
      {...typeProps}
      {...itemProps}
      options={options}
      label={label}
    />
  );
}

function TrialQueriesDisplay (props) {
  const { uncontrols, extralControls, items, params, } = props;
  const _items = items.flat().filter(_ => !difference(uncontrols, extralControls).includes(_.name)).map((item) => {
    const { name, type, options, } = item;
    const _value = params[name];
    const value = ((({
      multiSelect: _ => options?.filter(_ => _value?.includes(_.value)).map(_ => _.label).join(', '),
      select: _ => options?.find(_ => _.value === _value)?.label,
      numeric: _ => isEmpty(_value) ? null : numeral(_value).format('0[.][0000000]'),
    })[type]) || (_ => _value))();
    return {
      ...item,
      value,
    };
  });

  return (
    <div className="d-flex flex-wrap gap-2">
      {
        _items.filter(_ => !isEmpty(_.value)).map((item) => {
          const { label, value, name, } = item;
          return (
            <span>
              <span className="badge badge-secondary text-left text-wrap">
                {label}: {value}
              </span>
            </span>
          );
        })
      }
    </div>
  );
}

function TrialQueriesModalContent (props) {
  const { apply, company, items, screenType, screenQueries, readOnly, uncontrols, } = props;
  const queryParams = useQueryParams();
  const renderFormHeader = (statedFields, screenQuery) => {
    return (
      <div className="mb-3">
        <TrialQueriesDisplay uncontrols={uncontrols} items={items} extralControls={statedFields.extralControls.value} params={screenQuery?.queryParams ?? queryParams} />
      </div>
    );
  };

  return (
    <div>
      <div className="d-flex flex-column align-items-end mb-2 gap-1">
        <ModalButton title="保存された表示条件" color="link" size="sm" content={_ => <ScreenQueriesModalContent apply={apply} items={items} company={company} screenType={screenType} screenQueries={screenQueries} toggleModal={_.toggleModal} readOnly={readOnly} uncontrols={uncontrols} renderFormHeader={renderFormHeader} />} modalProps={{ style: { minWidth: 1100 } }}>
          <span className="fas fa-list mr-1" />
          保存された表示条件
        </ModalButton>
        {
          !readOnly && (
            <AddButton label="この表示条件を保存" iconClassName="fas fa-save" itemRef={_ => company.ref.collection('screenQueries').doc()} processValues={_ => ({ ..._, screenType, queryParams, })} FormModal={ModelFormModal} formProps={{ title: '表示条件 追加', fields: screenQueryFields({ uncontrols, items, }), renderFormHeader, }} />
          )
        }
      </div>
      <div className="d-flex flex-column gap-2">
        {
          items.map((items, i) => (
            <div key={i} className="d-flex flex-wrap gap-1 align-items-end">
              {
                items.filter(_ => !uncontrols.includes(_.name)).map((item) => {
                  return (
                    <Item key={item.name} item={item} />
                  );
                })
              }
            </div>
          ))
        }
      </div>
    </div>
  );
}

function ScreenQueriesModalContent (props) {
  const { apply, items, company, screenType, toggleModal, readOnly, uncontrols = [], renderFormHeader, } = props;
  const _screenQueries = useCollectionSubscription(!readOnly && company.ref.collection('screenQueries').where('screenType', '==', screenType), [company, screenType, readOnly], { initialValue: null });
  const screenQueries = props.screenQueries || _screenQueries;
  const sortedScreenQuerires = sortBy(screenQueries, _ => _.createdAt.toDate?.());

  return (
    <div>
      <table className="table">
        <thead>
          <tr>
            <th style={{ minWidth: 200 }}>名称</th>
            <th style={{ minWidth: 200 }}>内容</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {
            sortedScreenQuerires.map((screenQuery) => {
              const { id, ref, name, queryParams, extralControls, createdAt, isDefault = false, } = screenQuery;
              const onClick = () => {
                apply(screenQuery);
                toggleModal(false);
              };
              const onClickDefault = async () => {
                if(!window.confirm(`デフォルト${isDefault ? 'を解除' : 'に'}します。よろしいですか？`)) return;

                const batch = db.batch();
                sortedScreenQuerires.map(_ => batch.update(_.ref, { isDefault: false, }));
                batch.update(ref, { isDefault: !isDefault, });
                await batch.commit();
              };

              return (
                <tr key={id}>
                  <td>
                    <div>
                      {name}
                    </div>
                    <div>
                      {isDefault && <span className="badge badge-info">デフォルト</span>}
                    </div>
                  </td>
                  <td>
                    <TrialQueriesDisplay uncontrols={uncontrols} extralControls={extralControls} items={items} params={queryParams} />
                  </td>
                  <td className="text-right text-nowrap">
                    <Button color="primary" outline onClick={onClick}>
                      適用する
                    </Button>
                    {
                      !readOnly && (
                        <ProgressButton process={onClickDefault} className="ml-1">
                          デフォルト{isDefault ? 'を解除' : 'にする'}
                        </ProgressButton>
                      )
                    }
                    {!readOnly && <EditButton itemRef={ref} className="ml-1" FormModal={ModelFormModal} formProps={{ title: '表示条件 編集', renderFormHeader: _ => renderFormHeader(_, screenQuery), fields: screenQueryFields({ uncontrols, items, }), }} />}
                    {!readOnly && <DeleteButton itemRef={ref} className="ml-1" />}
                  </td>
                </tr>
              );
            })
          }
        </tbody>
      </table>
    </div>
  );
}
