import React, { useEffect } from 'react';
import { Button, Modal, ModalBody, ModalFooter, Form, } from 'reactstrap';
import { last, groupBy, pickBy, pick, get, keyBy, omit, mapValues, } from 'lodash';
import { useList, useToggle, } from 'react-use';
import { format as formatDate, addDays, } from 'date-fns';

import { dateStringToDate, } from '../../utils';
import useFormState from '../hooks/useFormState';
import Field from '../Field';
import { fiscalYearFields, } from '../../shared/models/company';
import ListForm from '../ListForm';

const { entries, } = Object;

export default function PeriodSettingFormModal(props) {
  const { isOpen, company, values, onClickClose, } = props;
  const { true: kansapoFiscalYears = [], false: freeeFiscalYears = [], } = groupBy(company.fiscalYears, _ => !!_.viaKansapo);
  const [fiscalYears, { set: setItems, updateAt: updateItemAt }] = useList(kansapoFiscalYears.map(_ => ({ ...(_.start_date && { start_date: dateStringToDate(_.start_date) }), ...( _.end_date && { end_date: dateStringToDate(_.end_date) }), })) || [{ start_date: null, end_date: null }, { start_date: null, end_date: null }]);
  const [isSubmitting, toggleSubmitting] = useToggle(false);
  const isUnsubmittable = (fiscalYears.some(_ => !_.isValid));
  console.log(freeeFiscalYears, kansapoFiscalYears);
  const onSubmit = async (event) => {
    event.preventDefault();
    if(isUnsubmittable) return;

    toggleSubmitting(true);
    await props.onSubmit({
      fiscalYears: [
        ...freeeFiscalYears,
        ...fiscalYears.map(_ => ({
          viaKansapo: true,
          start_date: formatDate(_.start_date, 'YYYY-MM-DD'),
          end_date: formatDate(_.end_date, 'YYYY-MM-DD'),
          use_industry_template: last(freeeFiscalYears).use_industry_template,
        })),
      ],
    }, { onClickClose });
    toggleSubmitting(false);
  };

  return (
    <Modal isOpen={isOpen} style={{ minWidth: 800 }}>
      <div className="modal-header">
        <div className="w-100 d-flex justify-content-between">
          <h5 className="modal-title">
            会計期間
          </h5>
        </div>
      </div>
      <Form onSubmit={onSubmit}>
        <ModalBody>
          <div className="mt-3">
            <ListForm
              addButtonLabel="会計期間追加"
              items={fiscalYears}
              renderItem={(item, itemIndex) => {
                return (
                  <div className="card p-3">
                    <ItemForm
                      company={company}
                      freeeFiscalYears={freeeFiscalYears}
                      index={itemIndex}
                      values={item}
                      onChange={_ => updateItemAt(itemIndex, { ...item, ..._, })}
                    />
                  </div>
                );
              }}
              onChange={setItems}
            />
          </div>
        </ModalBody>
        <ModalFooter>
          <Button className="cancel" color="secondary" onClick={onClickClose}>閉じる</Button>
          <Button className="save" type="submit" color="primary" onClick={onSubmit} disabled={isUnsubmittable || isSubmitting}>保存</Button>
        </ModalFooter>
      </Form>
    </Modal>
  );
};

function ItemForm (props) {
  const { values, company, onChange, freeeFiscalYears, } = props;
  const statedFields = useFormState(values, fiscalYearFields({ company, freeeFiscalYears, }), values);
  useEffect(() => {
    onChange({
      ...mapValues(statedFields, 'value'),
      isValid: Object.values(statedFields).every(_ => _.isValid),
    });
  }, [...Object.values(statedFields).map(_ => _.value)]);

  return (
    <div>
      <div className="d-flex">
        {
          entries(statedFields).map(([fieldName, fieldSetting]) => (
            <div key={fieldName} className="mr-2" style={{ minWidth: 150 }}>
              <Field
                key={fieldName}
                name={fieldName}
                {...fieldSetting}
              />
            </div>
          ))
        }
      </div>
    </div>
  );
}
