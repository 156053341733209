import React, { Component, Fragment, useState, useRef, } from 'react';
import { format as formatDate, startOfMonth, endOfMonth, addYears, addMonths, } from 'date-fns';
import { last, chunk, uniqBy, orderBy, uniq, isEqual, get, sumBy, isEmpty, isNumber as _isNumber, groupBy, sortBy, keyBy, range, debounce, pick, } from 'lodash';
import numeral from 'numeral';

import { dimensions, budgetSubjectTypes, dimensionValues, } from '../shared/config';
import useQueryParams from './hooks/useQueryParams';

const isFiniteNumber = _ => _isNumber(_) && isFinite(_);

export default function PublicBudgetCellModalContent (props) {
  const { targetRangeMonths, closingDate, subItemName, freeeSubjects, freeeSubjectValuesById, budgetSubjectType, } = props;
  const queryParams = useQueryParams();
  const { documentType = 'pl', dimension = 'none', } = queryParams;

  return (
    <div className="d-flex justify-content-center position-relative" style={{ zIndex: 0 }}>
      <table className="table table-bordered">
        <thead className="text-center">
          <tr>
            <th>
              freee{budgetSubjectTypes[budgetSubjectType]?.label}
            </th>
            <th>
              当期実績
            </th>
          </tr>
        </thead>
        <tbody>
          {
            freeeSubjects.map((freeeSubject) => {
              const freeeSubjectValues = freeeSubjectValuesById[freeeSubject.id];
              const freeeUrl = `https://secure.freee.co.jp/reports/trial_profit_and_loss?category=default&compare_type=none&start_date=${formatDate(startOfMonth(targetRangeMonths?.[0] ?? closingDate), 'YYYY-MM-DD')}&end_date=${formatDate(last(targetRangeMonths) ?? closingDate, 'YYYY-MM-DD')}&${subItemName ? `${dimensionValues[dimension]?.breakdownDisplayType}_name=${subItemName}` : ''}&report_type=${documentType}&${budgetSubjectTypes[budgetSubjectType]?.freeeParameterName}=${freeeSubject?.name}&term_type=monthly`;
              // NOTE: 総勘定元帳勘定へ飛ばしたいところ
              // const freeeUrl = `https://secure.freee.co.jp/reports/general_ledgers/show?start_date=${formatDate(startOfMonth(targetRangeMonths?.[0] ?? closingDate), 'YYYY-MM-DD')}&end_date=${formatDate(last(targetRangeMonths) ?? closingDate, 'YYYY-MM-DD')}&${budgetSubjectTypes[budgetSubjectType]?.freeeParameterName}=${freeeSubject?.id}&name=${itemName}`;
              return (
                <tr key={freeeSubject.id}>
                  <th>
                    {freeeSubject?.name}
                  </th>
                  <td className="text-right">
                    <a target="_blank" href={freeeUrl}>
                      {isFiniteNumber(freeeSubjectValues?.amount) ? numeral(freeeSubjectValues?.amount).format() : '-'}
                    </a>
                  </td>
                </tr>
              );
            })
          }
        </tbody>
      </table>
    </div>
  );
}
