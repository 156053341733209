import React, { Fragment, useState, useEffect, useMemo, } from 'react';
import { pick, mapValues, orderBy, countBy, omit, pickBy, groupBy, get, keyBy, difference, last, sortBy, } from 'lodash';
import { collection, query, where, getCountFromServer, } from 'firebase/firestore';
import { Button, FormGroup, Input, Label, } from 'reactstrap';
import { toast } from 'react-toastify';
import { format as formatDate, endOfMonth, startOfMonth, addMinutes, } from 'date-fns';
import { useAsync, useToggle, useMap, useList, } from 'react-use';
import { Link } from 'react-router-dom';
import qs from 'qs';
import classnames from 'classnames';
import numeral from 'numeral';
import { TextEncoder, } from 'text-encoding';
import { unparse as unparseCsv, } from 'papaparse';
import fileDownload from 'js-file-download';

import firebase from '../../firebase';
import { startOfMonthByFiscalYears, autoLink, nl2br, } from '../../utils';
import { formatMentions } from '../../shared/comment';
import { mapKeysToJa, } from '../../shared/texts';
import { modelNames, journalTags, detectionStatuses, dimensions, screenQueryUncontrols, } from '../../shared/config';
import { fields, } from '../../shared/models/controlReport';
import { types, } from '../../shared/models/detection';
import env from '../../env';
import useDocumentSubscription from '../hooks/useDocumentSubscription';
import useCollectionSubscription from '../hooks/useCollectionSubscription';
import useQueryParams from '../hooks/useQueryParams';
import ModelFormModal from '../modals/ModelFormModal';
import HelpLink from '../HelpLink';
import CompanyPage from '../hocs/CompanyPage';
import ProgressButton from '../ProgressButton';
import EditButton from '../EditButton';

const db = firebase.firestore();
const auth = firebase.auth();
const companiesRef = db.collection('companies');
const { entries, keys } = Object;

export default CompanyPage(CompanyControlReports);

function CompanyControlReports(props) {
  const { role, members, company, user, period, journalsFetchJobs = [], targetMonth, company: { fiscalYears, sourceId: companySourceId }, match: { params: { companyId } }, location } = props;
  const isEditable = user.admin || ['owner', 'admin'].includes(role);
  const membersById = keyBy(members, 'id');
  const controlReportRef = company.ref.collection('controlReports').doc(targetMonth.toString());
  const controlReport = useDocumentSubscription(controlReportRef, [company.id, targetMonth]);
  const customJournalsCheckRules = useCollectionSubscription(company.ref.collection('customJournalsCheckRules').orderBy('createdAt'), [company.id]);
  const comments = useCollectionSubscription(company.ref.collection('comments').where('yearMonth', '==', targetMonth.toString()), [company.id, targetMonth]);
  const sortedComments = useMemo(_ => orderBy(comments.filter(_ => !_.done), _ => _.createdAt.toDate(), 'desc'), [comments]);
  const { value: counts, error, } = useAsync(async () => {
    return keyBy(
      (await Promise.all([
        {
          type: 'journal',
          detectionCollectionName: 'journalsCheckDetections',
          detectionKeys: [
            ...keys(omit(pickBy(types, _ => _.targetType !== 'balance'), 'custom')).map(_ => ({ keyType: 'type', keyValue: _, })),
            ...customJournalsCheckRules.filter(_ => _.ruleType === 'journal').map(_ => ({ keyType: 'customJournalsCheckRuleId', keyValue: _.id })),
          ],
        },
        {
          type: 'balance',
          detectionCollectionName: 'trialsCheckDetections',
          detectionKeys: [
            ...keys(omit(pickBy(types, _ => _.targetType !== 'journal'), 'custom')).map(_ => ({ keyType: 'type', keyValue: _, })),
            ...customJournalsCheckRules.filter(_ => _.ruleType !== 'journal').map(_ => ({ keyType: 'customJournalsCheckRuleId', keyValue: _.id })),
          ],
        },
      ].map(async ({ type, detectionCollectionName, detectionKeys, }) => {
        return (await Promise.all(detectionKeys.map(async ({ keyType, keyValue }) => {
          return await Promise.all(['initial', 'ignored'].map(async (status) => {
            const { count } = (await getCountFromServer(query(
              collection(company.ref, detectionCollectionName),
              where(keyType, '==', keyValue),
              ...(keyType === 'customJournalsCheckRuleId' ? [where('type', '==', 'custom')] : []),
              where('status', '==', status),
              ...(
                type === 'journal' ? [
                  where('journal.date', '>=', formatDate(startOfMonth(targetMonth + '01'), 'YYYY/MM/DD')),
                  where('journal.date', '<=', formatDate(endOfMonth(targetMonth + '01'), 'YYYY/MM/DD')),
                ] : [
                  where('targetMonth', '==', targetMonth),
                ]
              ),
            ))).data();
            return { type, keyType, keyValue, status, count, };
          }));
        }))).flat();
      }))).flat(),
      _ => [_.keyValue, _.status].join('__'),
    );
  }, [customJournalsCheckRules, targetMonth]);
  const screenQueries = useCollectionSubscription(company.ref.collection('screenQueries').where('isControlled', '==', true), [company]);
  const screenQueriesGroupedByScreenType = groupBy(screenQueries, 'screenType');
  console.log(controlReport);
  const onClickSignOff = async () => {
    if(!window.confirm('サインオフします。よろしいですか？')) return;

    await controlReport.ref.set({
      confirmedAt: new Date(),
      confirmedBy: pick(user, ['id', 'email', 'displayName']),
      updatedAt: new Date(),
    }, { merge: true });
  };

  return (
    <div className="company-custom-account-items">
      <div className="container py-5">
        <div className="d-flex justify-content-end mb-3">
          <HelpLink text="内部統制レポートを確認する" />
        </div>
        <div className="d-flex justify-content-center mb-2">
          <h3>内部統制レポート</h3>
        </div>
        <hr className="my-5" />
        <div>
          <h4>チェック結果一覧</h4>
          <table className="table">
            <thead className="text-center thead-light">
              <tr>
                <th>チェック種別</th>
                <th>検出種別</th>
                <th>検出残件数</th>
                <th>無視件数</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {
                [
                  {
                    type: 'journal',
                    typeLabel: '仕訳チェック',
                    path: 'journalsCheck',
                    rows: [
                      ...entries(omit(pickBy(types, _ => _.targetType !== 'balance'), 'custom')).map(([type, { label }]) => (
                        { key: type, ruleLabel: label }
                      )),
                      ...customJournalsCheckRules.filter(_ => _.ruleType === 'journal').map(_ => ({
                        key: _.id,
                        ruleLabel: _.name,
                      }))
                    ]
                  },
                  {
                    type: 'balance',
                    typeLabel: '残高チェック',
                    path: 'trialsCheck',
                    rows: [
                      ...entries(omit(pickBy(types, _ => _.targetType !== 'journal'), 'custom')).map(([type, { label }]) => (
                        { key: type, ruleLabel: label }
                      )),
                      ...customJournalsCheckRules.filter(_ => _.ruleType !== 'journal').map(_ => ({
                        key: _.id,
                        ruleLabel: _.name,
                      }))
                    ]
                  },
                ].map(({ type, typeLabel, path, rows }) => {
                  return (
                    <Fragment key={type}>
                      {
                        rows.map(({ key, ruleLabel }, i) => {
                          return (
                            <tr key={key}>
                              {
                                i === 0 && (
                                  <th rowSpan={rows.length}>
                                    {typeLabel}
                                  </th>
                                )
                              }
                              <td>
                                {ruleLabel}
                              </td>
                              <td className="text-right">
                                {numeral(counts?.[`${key}__initial`]?.count).format()}
                              </td>
                              <td className="text-right">
                                {numeral(counts?.[`${key}__ignored`]?.count).format()}
                              </td>
                              <td className="text-right">
                                <Link to={`/companies/${companyId}/${path}?${qs.stringify({ period, targetMonth, type: key, })}`} target="_blank">
                                  確認する
                                  <span className="ml-1 fas fa-external-link-alt" />
                                </Link>
                              </td>
                            </tr>
                          );
                        })
                      }
                    </Fragment>
                  );
                })
              }
            </tbody>
          </table>
        </div>
        <hr className="my-5" />
        <div>
          <h4>内部統制用表示条件一覧</h4>
          <table className="table">
            <thead className="text-center thead-light">
              <tr>
                <th>画面</th>
                <th>表示条件名</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {
                entries(pick(modelNames, screenQueries.map(_ => _.screenType))).map(([modelName, { label }]) => {
                  const screenQueries = screenQueriesGroupedByScreenType[modelName] || [];
                  return (
                    <Fragment key={modelName}>
                      {
                        screenQueries.map((screenQuery) => {
                          const { id, ref, name, screenType, queryParams, extralControls, } = screenQuery;
                          const queryParamsString = qs.stringify({
                            period,
                            targetMonth,
                            ...omit(queryParams, [...difference(screenQueryUncontrols[screenType], extralControls), 'period', 'targetMonth', 'documentType', 'tabKey', 'commenteeKey']),
                          });
                          return (
                            <tr key={id}>
                              <td>
                                {label}
                              </td>
                              <td>
                                {name}
                              </td>
                              <td>
                                <Link to={`/companies/${companyId}/${screenType}?${queryParamsString}`} target="_blank">
                                  確認する
                                  <span className="ml-1 fas fa-external-link-alt" />
                                </Link>
                              </td>
                            </tr>
                          );
                        })
                      }
                    </Fragment>
                  );
                })
              }
            </tbody>
          </table>
        </div>
        <hr className="my-5" />
        <div>
          <h4>未完了コメント一覧</h4>
          <table className="table">
            <thead className="text-center thead-light">
              <tr>
                <th>ユーザー名</th>
                <th>日時</th>
                <th>内容</th>
                <th>コメント場所</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {
                sortedComments.map((comment) => {
                  const { id, ref, createdBy, createdAt, location, body, about, commenteeKey, } = comment;
                  const url = location && new URL(location);
                  const onClickDone = async () => {
                    if(!window.confirm('完了にします。よろしいですか？')) return;

                    await ref.update({ done: true, updatedAt: new Date(), });
                    toast.success('完了にしました');
                  };

                  return (
                    <tr key={id}>
                      <td>
                        {membersById[createdBy.uid]?.displayName}
                      </td>
                      <td>
                        {formatDate(createdAt.toDate(), 'YYYY/MM/DD HH:mm:ss')}
                      </td>
                      <td>
                        <div dangerouslySetInnerHTML={{ __html: nl2br(autoLink(formatMentions(body), { linkAttr: { target: '_blank' } })) }} />
                      </td>
                      <td>
                        <Link to={url.pathname + '?' + qs.stringify({ ...qs.parse(url.search.slice(1)), commenteeKey })} target="_blank">
                          {about}
                          <span className="ml-1 fas fa-external-link-alt" />
                        </Link>
                      </td>
                      <td>
                        {
                          isEditable && (
                            <ProgressButton process={onClickDone}>
                              完了する
                            </ProgressButton>
                          )
                        }
                      </td>
                    </tr>
                  );
                })
              }
            </tbody>
          </table>
        </div>
        <hr className="my-5" />
        <div>
          <h4>総括メモ</h4>
          <div className="d-flex justify-content-end mb-1">
            {
              isEditable && (
                <EditButton itemRef={controlReportRef} usesSet FormModal={ModelFormModal} formProps={{ title: '総括メモ 編集', fields: fields() }} />
              )
            }
          </div>
          <div className="card p-2" style={{ whiteSpace: 'pre-line', minHeight: 150, }}>
            {controlReport?.note}
          </div>
        </div>
        <hr className="my-5" />
        <div>
          <h4>サインオフ</h4>
          <div className="d-flex justify-content-start mb-1">
            <ProgressButton color="primary" size="lg" process={onClickSignOff} disabled={!isEditable}>
              確認
            </ProgressButton>
          </div>
          <div className="d-flex gap-2 text-muted">
            <div>
              {controlReport?.confirmedBy?.displayName}
            </div>
            <div>
              {controlReport?.confirmedAt && formatDate(controlReport.confirmedAt.toDate(), 'YYYY/MM/DD HH:mm:ss')}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
