import React from 'react';
import { Button, } from 'reactstrap';
import { mapValues, pick, last, sortBy, omit } from 'lodash';
import { toast } from 'react-toastify';
import { useToggle } from 'react-use';
import { format as formatDate } from 'date-fns';
import classnames from 'classnames';
import retry from 'async-retry';

import firebase, { functions } from '../../firebase';
import { fieldDisplayValue } from '../../shared/util';
import { generalSettingsFields } from '../../shared/models/company';
import useCollectionSubscription from '../hooks/useCollectionSubscription';
import { log } from '../../utils';
import HelpLink from '../HelpLink';
import CompanyPage from '../hocs/CompanyPage';
import ModelFormModal from '../modals/ModelFormModal';
import PeriodSettingFormModal from '../modals/PeriodSettingFormModal';
import EditButton from '../EditButton';

const { entries, keys } = Object;
const db = firebase.firestore();
const companiesRef = db.collection('companies');
const saveCompanySecret = functions.httpsCallable('saveCompanySecret');

export default CompanyPage(CompanyGeneralSettings);

function CompanyGeneralSettings(props) {
  const { user, company, accountItems, } = props;
  const processValues = async (values) => {
    if(values.scraperPass) {
      await retry(async () => {
        await saveCompanySecret({ companyId: company.id, key: 'general', values: { scraperPass: values.scraperPass, } });
      });
    }
    return omit(values, 'scraperPass');
  };

  return (
    <div className="company-custom-sections">
      <div className="container py-5">
        <div className="d-flex justify-content-center mb-2">
          <h4>事業所設定</h4>
        </div>
        <div className="d-flex justify-content-end mb-3 gap-1">
          <EditButton label="会計期間編集" itemRef={company.ref} FormModal={PeriodSettingFormModal} formProps={{ company, }} />
          <EditButton color="primary" itemRef={company.ref} processValues={processValues} FormModal={ModelFormModal} formProps={{ title: '事業所設定', fields: generalSettingsFields(company, accountItems) }} withBatch={(batch, values) => log(company, 'generalSetting', 'update', user, { fields: mapValues(generalSettingsFields(company, accountItems), _ => pick(_, ['label', 'type'])), data: values, prevData: pick(company, keys(generalSettingsFields(company, accountItems))), }, batch)} />
        </div>
        <div>
          <table className="table table-hover table-bordered">
            <tbody className="thead-light">
              {
                entries(omit(generalSettingsFields(company, accountItems), ['scraperPass'])).map(([fieldName, fieldSetting]) => {
                  const { label, hidden = _ => false, } = fieldSetting;
                  return !hidden(company) && (
                    <tr key={fieldName}>
                      <th style={{ width: 300 }}>{label}</th>
                      <td style={{ wordBreak: 'break-all', }}>{fieldDisplayValue(company[fieldName], fieldSetting)}</td>
                    </tr>
                  );
                })
              }
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}
