const { isEmpty } = require('lodash');

const { keys, entries } = Object;

const fields = () => {
  return {
    note: {
      type: 'text',
      label: '総括メモ',
      rows: 10,
    },
  };
};

module.exports = {
  fields,
};
