const { pick, pickBy, isEmpty, omit, } = require('lodash');
const { isEmail, } = require('validator');

const { roles, screens, guestScreens, } = require('../config');
const { plans, } = require('../models/company');

const { keys, entries } = Object;

const fields = ({ company, isSelf, currentRole } = {}) => {
  return {
    role: {
      label: '権限',
      type: 'select',
      options: entries((isSelf || currentRole === 'owner') ? roles : omit(roles, ['owner'])).map(([k, v]) => ({ label: v, value: k, })),
      validations: {
        required: v => !isEmpty(v),
      },
      readOnly: _ => currentRole === 'owner',
    },
    enabledScreens: {
      label: 'アクセス可能な画面',
      type: 'multiSelect',
      options: (s) => {
        const availableScreens = pickBy(omit(screens, 'settings'), (v, k) => s.role === 'guest' ? guestScreens.includes(k) : true);
        return entries(pick(availableScreens, company?.enabledPlans.flatMap(_ => plans[_].screens))).map(([k, v]) => ({ label: v.label, value: k, }));
      },
      hidden: s => !['member', 'guest'].includes(s.role),
      hint: '指定しない場合は所定の画面にアクセスできます',
    },
    ...(
      company?.usesSlackNotifications && {
        slackUserId: {
          label: 'SlackユーザーID',
          type: 'string',
        },
      }
    ),
  };
};

const addingFields = (...args) => {
  return {
    email: {
      type: 'string',
      label: 'メールアドレス',
      validations: {
        required: v => !isEmpty(v),
        format: v => !v || isEmail(v),
      },
    },
    ...fields(...args),
    role: {
      ...fields(...args).role,
      options: fields(...args).role.options.filter(_ => _.value !== 'owner'),
    },
  };
};

module.exports = {
  fields,
  addingFields,
};
