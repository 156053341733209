const { isEmpty, sumBy, } = require('lodash');

const { entries } = Object;

const fields = ({ partnerTypes, }) => {
  return {
    name: {
      type: 'string',
      label: '名称',
      validations: {
        required: v => !isEmpty(v),
        unique: (v, s) => !s.conflicted && !partnerTypes.map(_ => _.name).includes(v),
      },
    },
  };
};

module.exports = {
  fields,
};
