const { pick, difference, groupBy, sum, sumBy, flatten, range, uniqBy, isEmpty } = require('lodash');
const { differenceInCalendarMonths, format: formatDate, addMonths, getYear, isWithinInterval, eachDay, startOfDay, startOfMonth, endOfDay, endOfMonth, addDays, } = require('date-fns');

const { dimensionsOfCompany, enabledBudgetSubjectTypes, plans, } = require('./company');
const { colors, bsAccountCategories, plAccountCategories, nonTrialAccountCategories, } = require('../config');

const { keys, entries } = Object;
const terms = {
  last1Year: {
    label: '過去1年',
    months: 12,
  },
  last2Year: {
    label: '過去2年',
    months: 24,
  },
  last3Year: {
    label: '過去3年',
    months: 36,
  },
};
const now = new Date();
const thisYear = getYear(now)
const months = flatten(range(thisYear - 10, thisYear + 1).map((year) => range(0, 12).map(_ => new Date(year, _, 1)))).filter(_ => _ < startOfMonth(now));
const monthOptions = [{ label: '今月', value: 'thisMonth' }, ...months.map(_ => ({ label: formatDate(_, 'YYYY/MM'), value: formatDate(_, 'YYYY/MM') })).reverse()];
const scopeTypes = {
  all: {
    label: '全社集計',
    hasBudgets: false,
  },
  section: {
    label: '部門別予実',
    hasBudgets: true,
  },
  segment1: {
    label: 'セグメント1別予実',
    hasBudgets: true,
  },
  segment2: {
    label: 'セグメント2別予実',
    hasBudgets: true,
  },
  segment3: {
    label: 'セグメント3別予実',
    hasBudgets: true,
  },
};
const xAxisUnits = {
  month: {
    label: '月次',
    xs: (months) => {
      return months.map(_ => ({ months: [_.month] }));
    },
  },
  quarter: {
    label: '四半期',
    xs: (months) => {
      const monthsGroupedByQuarter = groupBy(months, ({ month, periodEnd }) => {
        return formatDate(addMonths(month, differenceInCalendarMonths(periodEnd, month) % 3), 'YYYY/MM');
      });
      return Object.values(monthsGroupedByQuarter).map(_ => ({ months: _.map(_ => _.month) }));
    },
  },
  year: {
    label: '年次',
    xs: (months) => {
      const monthsGroupedByYear = groupBy(months, _ => formatDate(_.periodEnd, 'YYYY/MM'))
      return Object.values(monthsGroupedByYear).map(_ => ({ months: _.map(_ => _.month) }));
    },
  },
};
const chartTypes = {
  line: {
    label: '折れ線',
    chartComponentName: 'LineChart',
    metricComponentName: 'Line',
  },
  bar: {
    label: '棒',
    chartComponentName: 'BarChart',
    metricComponentName: 'Bar',
  },
  composed: {
    label: '混合',
    chartComponentName: 'ComposedChart',
  },
};
const sourceTypes = {
  budget: { 
    label: '予算',
  },
  trial: {
    label: '実績',
  },
};
const metricComponentTypes = {
  line: {
    label: '折れ線',
    componentName: 'Line',
  },
  bar: {
    label: '棒',
    componentName: 'Bar',
  },
};
const yAxisTypes = {
  left: { label: '左', },
  right: { label: '右', },
};
const valueTypes = {
  occurrence: {
    label: '発生',
  },
  closing: {
    label: '累計',
  },
};
const metricTypes = {
  accountItem: { 
    label: '勘定科目',
  },
  category: { 
    label: 'カテゴリ',
  },
  customAccountItem: { 
    label: 'カスタム科目',
  },
};

const termOptions = entries(terms).map(([k, v]) => ({ label: v.label, value: k }));
const xAxisUnitOptions = entries(xAxisUnits).map(([k, v]) => ({ label: v.label, value: k }));
const chartTypeOptions = entries(chartTypes).map(([k, v]) => ({ label: v.label, value: k }));
const metricComponentTypeOptions = entries(metricComponentTypes).map(([k, v]) => ({ label: v.label, value: k }));
const sourceTypeOptions = entries(sourceTypes).map(([k, v]) => ({ label: v.label, value: k }));
const yAxisTypeOptions = entries(yAxisTypes).map(([k, v]) => ({ label: v.label, value: k }));
const valueTypeOptions = entries(valueTypes).map(([k, v]) => ({ label: v.label, value: k }));
const metricTypeOptions = entries(metricTypes).map(([k, v]) => ({ label: v.label, value: k }));

const fields = ({ company, }) => {
  return {
    title: {
      type: 'string',
      label: 'タイトル',
      validations: {
        required: v => !isEmpty(v),
      },
    },
    baseMonth: {
      type: 'select',
      label: '基準月',
      options: monthOptions,
      initialValue: 'thisMonth',
      validations: {
        required: v => !isEmpty(v),
      },
    },
    term: {
      type: 'select',
      label: '期間',
      options: termOptions,
      initialValue: 'last1Year',
      validations: {
        required: v => !isEmpty(v),
      },
    },
    scopeType: {
      type: 'select',
      label: '集計種別',
      options: entries(pick(scopeTypes, company.enabledPlans.flatMap(_ => plans[_].customChart.scopeTypes).filter(_ => ['all', ...keys(enabledBudgetSubjectTypes(company))].includes(_)))).map(([k, v]) => ({ label: v.label, value: k })),
      initialValue: 'all',
      validations: {
        required: v => !isEmpty(v),
      },
    },
    xAxisUnit: {
      type: 'select',
      label: 'x軸単位',
      options: xAxisUnitOptions,
      initialValue: 'month',
      validations: {
        required: v => !isEmpty(v),
      },
    },
    chartType: {
      type: 'select',
      label: 'チャート種別',
      options: chartTypeOptions,
      initialValue: 'line',
      validations: {
        required: v => !isEmpty(v),
      },
    },
  };
};

const metricFields = ({ company, scopeType, chartType, customSections, customSegment1s, customSegment2s, customSegment3s, accountItems, customAccountItems, partners, freeeItems, sections, segment1s, segment2s, segment3s, index, }) => {
  return {
    customSectionId: {
      type: 'select',
      label: 'カスタム部門',
      options: customSections.map(_ => ({ label: _.name, value: _.id })),
      hidden: _ => scopeType !== 'section',
      validations: {
        required: v => !isEmpty(v),
      },
    },
    customSegment1Id: {
      type: 'select',
      label: 'セグメント1',
      options: customSegment1s.map(_ => ({ label: _.name, value: _.id })),
      hidden: _ => scopeType !== 'segment1',
      validations: {
        required: v => !isEmpty(v),
      },
    },
    customSegment2Id: {
      type: 'select',
      label: 'セグメント2',
      options: customSegment2s.map(_ => ({ label: _.name, value: _.id })),
      hidden: _ => scopeType !== 'segment2',
      validations: {
        required: v => !isEmpty(v),
      },
    },
    customSegment3Id: {
      type: 'select',
      label: 'セグメント3',
      options: customSegment3s.map(_ => ({ label: _.name, value: _.id })),
      hidden: _ => scopeType !== 'segment3',
      validations: {
        required: v => !isEmpty(v),
      },
    },
    sourceType: {
      type: 'select',
      label: '予実種別',
      options: sourceTypeOptions,
      initialValue: 'budget',
      hidden: _ => !scopeTypes[scopeType].hasBudgets,
      validations: {
        required: v => !isEmpty(v),
      },
    },
    metricType: {
      type: 'select',
      label: '指標種別',
      options: metricTypeOptions,
      validations: {
        required: v => !isEmpty(v),
      },
      initialValue: 'accountItem',
    },
    metricId: {
      type: 'select',
      label: '指標',
      options: ({ metricType }) => {
        return (({
          accountItem: _ => accountItems.map(_ => ({ label: _.name, value: _.id })),
          category: _ => difference([...bsAccountCategories, ...plAccountCategories], nonTrialAccountCategories).map(_ => ({ label: _, value: _, })),
          customAccountItem: _ => customAccountItems.map(_ => ({ label: _.name, value: _.id })),
        })[metricType] || (_ => []))();
      },
      validations: {
        required: v => !isEmpty(v),
      },
    },
    dimension: {
      type: 'select',
      label: '明細種別',
      options: entries(dimensionsOfCompany(company)).map(([k, v]) => ({ label: v, value: k })),
      hidden: _ => !(scopeType === 'all' && _.metricType === 'accountItem'),
      initialValue: 'none',
      validations: {
        required: v => !isEmpty(v),
      },
    },
    subItemName: {
      type: 'select',
      label: '明細項目',
      options: ({ dimension }) => {
        return (({
          none: _ => [],
          partners: _ => partners.map(_ => ({ label: _.name, value: _.name })),
          items: _ => freeeItems.map(_ => ({ label: _.name, value: _.name })),
          sections: _ => sections.map(_ => ({ label: _.name, value: _.name })),
          segment1s: _ => segment1s.map(_ => ({ label: _.name, value: _.name })),
          segment2s: _ => segment2s.map(_ => ({ label: _.name, value: _.name })),
          segment2s: _ => segment2s.map(_ => ({ label: _.name, value: _.name })),
        })[dimension] || (_ => []))();
      },
      hidden: _ => !(scopeType === 'all' && _.metricType === 'accountItem' && _.dimension !== 'none'),
      validations: {
        required: v => !isEmpty(v),
      },
    },
    valueType: {
      type: 'select',
      label: '数値種別',
      options: valueTypeOptions,
      initialValue: 'occurrence',
      hidden: _ => scopeTypes[scopeType].hasBudgets,
      validations: {
        required: v => !isEmpty(v),
      },
    },
    yAxisType: {
      type: 'select',
      label: 'Y軸',
      options: yAxisTypeOptions,
      initialValue: 'left',
      validations: {
        required: v => !isEmpty(v),
      },
    },
    componentType: {
      type: 'select',
      label: '表示形式',
      options: metricComponentTypeOptions,
      initialValue: 'line',
      hidden: _ => chartType !== 'composed',
      validations: {
        required: v => !isEmpty(v),
      },
    },
    color: {
      type: 'select',
      label: '色',
      validations: {
        required: v => v != null,
      },
      initialValue: index,
    },
  }
};

module.exports = {
  fields,
  metricFields,
  terms,
  xAxisUnits,
  chartTypes,
  metricComponentTypes,
  yAxisTypes,
  valueTypes,
  scopeTypes,
  sourceTypes,
};
