const { isEmpty, sumBy, } = require('lodash');

const { entries } = Object;
const types = {
  customer: { label: '得意先', },
  supplier: { label: '仕入先', },
  officer: { label: '役員', },
  staff: { label: '従業員', },
  other: { label: 'その他', },
};
const typeLabels = Object.values(types).map(_ => _.label);
const computeCreditBalance = (creditLimit, tradeReceivableNames, tradePayableNames, trialPartnerItems) => {
  return creditLimit
    - sumBy(trialPartnerItems.filter(_ => tradeReceivableNames.includes(_.account_item_name)), 'closing_balance')
    + sumBy(trialPartnerItems.filter(_ => tradePayableNames.includes(_.account_item_name)), 'closing_balance');
};

const fields = ({ freeePartners, customPartners, allPartnerTypes, } ) => {
  return {
    types: {
      type: 'multiSelect',
      label: '区分',
      options: allPartnerTypes.map(_ => ({ label: _.name, value: _.id, })),
      validations: {
        required: v => !isEmpty(v),
      },
      initialValue: [],
    },
    name: {
      type: 'string',
      label: '名称',
      validations: {
        required: v => !isEmpty(v),
        unique: (v, s) => !s.conflicted && !customPartners.map(_ => _.name).includes(v),
      },
    },
    freeePartnerIds: {
      type: 'multiSelect',
      label: 'freee取引先',
      options: freeePartners.map(_ => ({ label: _.name, value: _.id })),
      validations: {
        required: v => v != null && !isEmpty(v.toString()),
      },
      initialValue: [],
    },
    creditLimit: {
      label: '与信限度額',
      type: 'integer',
      validations: {
        greaterThanOrEqualTo0: v => v == null || v >= 0,
      },
      hidden: _ => !_.types.includes('customer'),
      initialValue: 0,
    },
  };
};

const fieldsForImport = ({ allPartnerTypes, freeePartners, customPartners = [], } = {}) => {
  const freeePartnerNames = freeePartners.map(_ => _.name);
  const allPartnerTypeNames = allPartnerTypes.map(_ => _.name);
  return {
    types: {
      validations: {
        required: v => !isEmpty(v),
        exists: v => v?.split(',').filter(_ => _).every(_ => allPartnerTypeNames.includes(_)),
      },
    },
    name: {
      validations: {
        required: v => !isEmpty(v),
        unique: (v, s) => !s.conflicted && !customPartners.map(_ => _.name).includes(v),
      },
    },
    freeePartners: {
      validations: {
        required: v => !isEmpty(v),
        exists: v => v.split('\n').filter(_ => _).every(_ => freeePartnerNames.includes(_)),
      },
    },
    creditLimit: {
      validations: {
        greaterThanOrEqualTo0: v => v == null || v >= 0,
      },
    },
  };
};

module.exports = {
  computeCreditBalance,
  fields,
  fieldsForImport,
  types,
};
