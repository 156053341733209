import React from 'react';

const helps = {
  'ログインする': 'https://kansapo.wraptas.site/45ca1f72d7e54259a1b79156375d10a3',
  'freeeと連携する': 'https://kansapo.wraptas.site/b1f7457087114b04bc5e63bc1d9a6eaf',
  '事業所情報を同期する': 'https://kansapo.wraptas.site/76006fb8a26c4bceab573227d80cab83',
  'ダッシュボードを活用する': 'https://kansapo.wraptas.site/cc38ac60c3ec4b8a87ec4a4c45509d76',
  '仕訳帳を同期する': 'https://kansapo.wraptas.site/edca302bf98b4da0a50604bed67d3815',
  '科目設定を行う': 'https://kansapo.wraptas.site/c418cebfe515454f91fc1d975ef092f2',
  '期間比較を活用する': 'https://kansapo.wraptas.site/f82c2bee24a2406bb8b244d96a9dc95d',
  '月次推移を活用する': 'https://kansapo.wraptas.site/b4f99f42c2004ab783d80df83fa8b5b7',
  'ドリルダウンして科目詳細を確認する': 'https://kansapo.wraptas.site/22382ef7e95148018c74576bd4478cff',
  '予実管理を行う': 'https://kansapo.wraptas.site/99ec09396a6643d5973044d3a06ad9d7',
  '予実管理を行う': 'https://kansapo.wraptas.site/99ec09396a6643d5973044d3a06ad9d7',
  '予実管理を行う': 'https://kansapo.wraptas.site/99ec09396a6643d5973044d3a06ad9d7',
  '取引先別分析を行う': 'https://kansapo.wraptas.site/77760b47a0044b5e8560accb19b9a958',
  '2明細分析を行う': 'https://kansapo.wraptas.site/142c47718a7d80bbb0e7f4f37985cfef',
  'カスタム科目を作成する': 'https://kansapo.wraptas.site/754d02984a2941468ba633c159294596',
  'カスタムルールを設定する': 'https://kansapo.wraptas.site/1a13dd46323345169d58afc6d16b60c9',
  'カスタム部門を作成する': 'https://kansapo.wraptas.site/31c66bad304442aab909191b7a39b2f5',
  'カスタムセグメントを作成する': 'https://kansapo.wraptas.site/51bf04bfcb454a62831ee4830b9a49fe',
  'カスタム取引先を作成する': 'https://kansapo.wraptas.site/e13a7ddb7a8b4281baca9edb1875155d',
  '仕訳チェックを行う': 'https://kansapo.wraptas.site/358d387500394b3cb252719f61070525',
  '残高チェックを行う': 'https://kansapo.wraptas.site/9fe2826e94134d5e9d8a21915ea4ec67',
  'メンバー・権限管理': 'https://kansapo.wraptas.site/f1ac27e4a14249b3a489e8265dadb59e',
  'セキュリティについて': 'https://kansapo.wraptas.site/9769b394693742db95ccfef4b99d3fb4',
  '仕訳分析を行う': 'https://kansapo.wraptas.site/4304e4f938b24dcfa94f13f5950e0958',
  '内部統制レポートを確認する': 'https://kansapo.wraptas.site/16bc47718a7d8090ba3bccd7ba895fbe',
};

export default function HelpLink (props) {
  const { url, text, ...extraProps } = props;
  return (
    <a href={helps[text]} target="_blank" className="unprint">
      <span className="fas fa-question-circle mr-2" />
      {text}
      <span className="small">
        <span className="fas fa-external-link-alt ml-1" />
      </span>
    </a>
  );
};
