const { sortBy, last, omit, pick, get, isEmpty, } = require('lodash');
const { isURL } = require('validator');

const { leftTrialDays } = require('../util');
const { budgetSubjectTypes, allDimensions, } = require('../config');
const { accountItemCategoriesByName, } = require('../category');

const { entries } = Object;
const plans = {
  budget: {
    label: '予実管理',
    screens: [
      'dashboard',
      'journals',
      'budgets',
      'customAccountItems',
      'customSections',
      'customSegments',
      'members',
      'logs',
      'settings',
    ],
    customChart: {
      scopeTypes: ['section', 'segment1', 'segment2', 'segment3'],
    },
    customAccountItem: {
      displays: [
        'sectionsBudgetsPl',
        'sectionBudgetsPl',
      ],
    },
  },
  settlement: {
    label: '決算支援',
    screens: [
      'dashboard',
      'journals',
      'journalsByTax',
      'accountItemSettings',
      'periodComparison',
      'monthlyTrends',
      'monthlyPartnerBalances',
      'twoDimensions',
      'customAccountItems',
      'customJournalsCheckRules',
      'customPartners',
      'journalsCheck',
      'trialsCheck',
      'controlReports',
      'members',
      'logs',
      'settings',
    ],
    customChart: {
      scopeTypes: ['all'],
    },
    customAccountItem: {
      displays: [
        'periodComparisonBs',
        'periodComparisonPl',
        'periodComparisonCr',
        'monthlyTrendsBs',
        'monthlyTrendsPl',
        'monthlyTrendsCr',
      ],
    },
  },
};
const features = {
  segments: { label: 'セグメント機能' },
};
const isTrialExpired = (company) => {
  return company.isTrial && leftTrialDays(company) < 0;
};
const canUseFeature = (company, feature) => company?.isTrial || company?.enabledFeatures?.includes(feature);
const usedSegments = (company) => {
  const usesSegment1 = canUseFeature(company, 'segments') && company.usesSegment1;
  const usesSegment2And3 = canUseFeature(company, 'segments') && company.usesSegment2And3;
  return [
    usesSegment1 && 1,
    usesSegment2And3 && 2,
    usesSegment2And3 && 3,
  ].filter(_ => _);
};
const dimensionsOfCompany = (company) => (
  omit(allDimensions, [...(!usedSegments(company).includes(1) ? ['segment1s'] : []), ...(!usedSegments(company).includes(2) ? ['segment2s', 'segment3s'] : [])])
);
const enabledBudgetSubjectTypes = (company) => {
  return pick(budgetSubjectTypes, ['section', usedSegments(company).includes(1) && 'segment1', ...(usedSegments(company).includes(2) ? ['segment2', 'segment3'] : [])]);
};
const generalSettingsFields = (company, accountItems) => {
  const bsAccountItems = accountItems.filter(_ => accountItemCategoriesByName[_.account_category]?.type === 'bs');
  return {
    disablesCommentFiles: {
      label: 'コメントのファイル添付不可',
      type: 'boolean',
      initialValue: false,
    },
    ...(
      company.enabledPlans?.includes('budget') && {
        disablesBudgetsShare: {
          label: '予実共有URL作成不可',
          type: 'boolean',
          initialValue: false,
        },
      }
    ),
    ...(
      company.enabledFeatures?.includes('segments') && {
        usesSegment1: {
          label: 'セグメント1の利用',
          type: 'boolean',
          initialValue: false,
        },
        usesSegment2And3: {
          label: 'セグメント2, 3の利用',
          type: 'boolean',
          initialValue: false,
        },
      }
    ),
    usesSlackNotifications: {
      label: 'Slack通知の利用',
      type: 'boolean',
      initialValue: false,
    },
    slackIncomingWebhookUrl: {
      type: 'string',
      label: 'Slack Incoming Webhook URL',
      validations: {
        required: v => !isEmpty(v),
        format: v => v == null || isURL(v),
      },
      hidden: _ => !_.usesSlackNotifications,
    },
    ...(
      company.enabledPlans?.includes('settlement') && {
        partnerCreditAccountItemIds: {
          label: '与信対象科目',
          type: 'multiSelect',
          options: bsAccountItems.map(_ => ({ label: _.name, value: _.id, })),
        },
      }
    ),
    ...(
      company.enabledPlans?.includes('budget') && {
        budgetSubjectDefaultDisplayCount: {
          label: '予実部門（セグメント）初期表示数',
          type: 'select',
          options: [5, 10, 15].map(_ => ({ label: _, value: _, })),
          initialValue: 5,
        },
      }
    ),
  };
};

const fiscalYearFields = ({ company, freeeFiscalYears, }) => {
  const lastYear = parseInt(last(sortBy(freeeFiscalYears.map(_ => _.end_date), _ => _)).slice(0, 4), 10);
  return {
    start_date: {
      label: '開始',
      type: 'date',
      selector: true,
      yearRange: [lastYear, lastYear + 5],
      inputProps: { onlyYearMonth: true },
      initialValue: null,
    },
    end_date: {
      label: '終了',
      type: 'date',
      selector: true,
      yearRange: [lastYear, lastYear + 5],
      inputProps: { onlyYearMonth: true },
      initialValue: null,
    },
  };
};

module.exports = {
  adminFields: () => {
    return {
      trialDays: {
        label: '試用期間(日)',
        type: 'integer',
        validations: {
          greaterThanOrEqualTo0: v => v != null && v >= 0,
        },
        initialValue: 30,
      },
      licenseCount: {
        label: 'ライセンス数',
        type: 'integer',
        validations: {
          required: v => v != null,
          greaterThanOrEqualTo0: v => v == null || v >= 0,
        },
        initialValue: 10,
      },
      isTrial: {
        label: 'トライアル',
        type: 'boolean',
        initialValue: true,
      },
      enabledPlans: {
        label: 'プラン',
        type: 'multiSelect',
        options: entries(plans).map(([k, { label }]) => ({ label, value: k })),
        initialValue: ['budget', 'settlement'],
      },
      enabledFeatures: {
        label: 'オプション',
        type: 'multiSelect',
        options: entries(features).map(([k, v]) => ({ label: v.label, value: k, })),
      },
      ipAddressRestriction: {
        label: 'IPアドレス制限',
        type: 'boolean',
        initialValue: false,
      },
      whiteIps: {
        label: 'IPアドレスホワイトリスト',
        type: 'text',
        hidden: _ => !_.ipAddressRestriction,
        placeholder: '改行区切り',
      },
      shouldDelete: {
        label: '削除する',
        type: 'boolean',
        hidden: _ => !_.isTrial,
        initialValue: false,
      },
    };
  },
  generalSettingsFields,
  plans,
  features,
  isTrialExpired,
  usedSegments,
  enabledBudgetSubjectTypes,
  dimensionsOfCompany,
  canUseFeature,
  fiscalYearFields,
};
