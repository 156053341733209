import React, { useState, useEffect, useMemo, Fragment } from 'react';
import { Button, Nav, NavItem, NavLink,  } from 'reactstrap';
import { orderBy, uniq, isEmpty, sumBy, groupBy, get, keyBy, last, sortBy, omit } from 'lodash';
import { toast } from 'react-toastify';
import { format as formatDate, endOfMonth, addMonths, addYears } from 'date-fns';
import { useList, useToggle, useDebounce, } from 'react-use';
import { useLocation, useHistory } from 'react-router-dom';
import numeral from 'numeral';
import qs from 'qs';
import classnames from 'classnames';
import { Link } from 'react-router-dom';
import { ResizableBox } from 'react-resizable';

import firebase from '../../firebase';
import { canWriteNote, } from '../../abilities';
import { getCollectionData } from '../../shared/firebase';
import { accountItemCategoriesByName } from '../../shared/category';
import { mapKeysToJa, } from '../../shared/texts';
import { computeCreditBalance, types as presetTypes, } from '../../shared/models/customPartner';
import { log, } from '../../utils';
import useCollectionSubscription from '../hooks/useCollectionSubscription';
import useQueryParams from '../hooks/useQueryParams';
import usePagination from '../hooks/usePagination';
import TrialQueriesSection from '../TrialQueriesSection';
import HelpLink from '../HelpLink';
import CompanyPage from '../hocs/CompanyPage';
import CommentsDropdown from '../CommentsDropdown';
import QuerySelector from '../QuerySelector';
import ExportButton from '../ExportButton';
import Pagination from '../Pagination';
import HoveredCommenter from '../HoveredCommenter';
import HoveredNoter from '../HoveredNoter';

const { entries } = Object;
const db = firebase.firestore();
const companiesRef = db.collection('companies');
const COLUMN_WIDTH = 160;
const ROW_HEIGHT = 130;
const countPerPage = 50;

// NOTE: あえてreact外のstateを使う
const hoveredCommenters = {};

export default CompanyPage(CompanyCustomPartners);

function CompanyCustomPartners(props) {
  const { sortedAccountItems: accountItems, company, user, role, period, targetMonth, members, match: { params: { companyId } } } = props;
  const history = useHistory();
  const location = useLocation();
  const queryParams = useQueryParams();
  const {
    customPartnerType,
    customPartnerIds,
    accountItemNamesForFilter,
  } = queryParams;
  const [columnWidths, { updateAt: updateColumnWidthAt, }] = useList([COLUMN_WIDTH, COLUMN_WIDTH, COLUMN_WIDTH * 0.8]);
  const partnerTypes = useCollectionSubscription(company.ref.collection('partnerTypes').orderBy('index'), [company.id]);
  const allPartnerTypes = useMemo(_ => [...entries(presetTypes).map(([k, v]) => ({ name: v.label, id: k, isPreset: true, })), ...partnerTypes], [partnerTypes]);
  const allPartnerTypesById = keyBy(allPartnerTypes, 'id');
  const partnerChunks = useCollectionSubscription(company.ref.collection('partnerChunks'), [companyId]);
  const freeePartners = [
    { id: 0, name: '未選択' },
    ...partnerChunks.flatMap(_ => _.data),
  ];
  const freeePartnersById = keyBy(freeePartners, 'id');
  const freeePartnersByName = keyBy(freeePartners, 'name');
  const [rowGroups, setRowGroups] = useState([]);
  const [isSettingRows, toggleSettingRows] = useToggle(false);
  const [isCommentRowToggled, toggleCommentRowToggled] = useToggle();
  const accountItemNames = accountItems.map(_ => _.name);
  const accountItemsById = keyBy(accountItems, 'id');
  const { debit: debitPartnerCreditAccountItemIds, credit: creditPartnerCreditAccountItemIds } = groupBy(company.partnerCreditAccountItemIds, _ => accountItemCategoriesByName[accountItemsById[_]?.account_category]?.direction);
  const tradeReceivableNames = accountItems.filter(_ => _.account_category === '売上債権' || debitPartnerCreditAccountItemIds?.includes(_.id)).map(_ => _.name);
  const tradePayableNames = accountItems.filter(_ => _.account_category === '仕入債務' || creditPartnerCreditAccountItemIds?.includes(_.id)).map(_ => _.name);
  const customPartners = useCollectionSubscription(customPartnerType && company.ref.collection('customPartners').where('types', 'array-contains', customPartnerType).orderBy('name'), [companyId, customPartnerType]);
  const trialSubItemChunks = useCollectionSubscription(company.ref.collection('trialSubItemChunks__partners').where('closingDate', '==', formatDate(endOfMonth(targetMonth + '01'), 'YYYY-MM-DD')), [targetMonth])
  const trialSubItems = useMemo(_ => trialSubItemChunks.flatMap(_ => _.subItems.map(__ => ({ ..._, ...__ }))), [trialSubItemChunks]);
  const trialPartnerItemsGroupedByFreeePartnerId = groupBy(trialSubItems, _ => get(freeePartnersByName, [_.name, 'id']));
  const comments = useCollectionSubscription(companiesRef.doc(companyId).collection('comments').where('queryKey', '==', 'partnerBalances').orderBy('createdAt'));
  const commentsGroupedByCommenteeKey = groupBy(comments, 'commenteeKey');
  const notes = useCollectionSubscription(company.ref.collection('notes').where('queryKey', '==', 'partnerBalances'));
  const notesByKey = keyBy(notes, 'noteKey');
  const generateRowGroups = (customPartners) => {
    return customPartners.map((customPartner) => {
      const { freeePartnerIds = [], } = customPartner;
      const trialPartnerItems = freeePartnerIds.flatMap(_ => trialPartnerItemsGroupedByFreeePartnerId[_] || []);
      const creditLimit = orderBy((customPartner?.creditLimits || []), 'yearMonth', 'desc').find(_ => _.yearMonth <= formatDate(targetMonth + '01', 'YYYYMM'))?.value || 0;
      const creditBalance = computeCreditBalance(creditLimit, tradeReceivableNames, tradePayableNames, trialPartnerItems);
      const rows = entries(groupBy(trialPartnerItems, 'account_item_name')).map(([accountItemName, items]) => {
        const openingBalance = sumBy(items, 'opening_balance');
        const debitAmount = sumBy(items, 'debit_amount') || 0;
        const creditAmount = sumBy(items, 'credit_amount') || 0;
        const closingBalance = sumBy(items, 'closing_balance');
        const commenteeKey = [customPartner.id, accountItemName, formatDate(targetMonth + '01', 'YYYYMM')].join('_');
        const noteKey = [customPartner.id, accountItemName, formatDate(targetMonth + '01', 'YYYYMM')].join('_');
        return {
          accountItemName,
          openingBalance,
          debitAmount,
          creditAmount,
          closingBalance,
          commenteeKey,
          noteKey,
        };
      });
      return {
        customPartner,
        creditLimit,
        creditBalance,
        rows,
      };
    });
  };
  const rowsDependencies = [accountItems, customPartners, trialSubItemChunks];
  useEffect(_ => toggleSettingRows(true), rowsDependencies);
  useDebounce(async () => {
    setRowGroups(generateRowGroups(customPartners));
    toggleSettingRows(false);
  }, 1000, rowsDependencies);
  const accountItemNameOptions = sortBy(uniq(rowGroups.flatMap(_ => _.rows.map(_ => _.accountItemName))), _ => accountItemNames.indexOf(_)).map(_ => ({ label: _, value: _ }));

  const onClickComment = (commenteeKey) => {
    const { [commenteeKey]: commenter } = hoveredCommenters;
    if(commenter) {
      commenter.open();
      commenter.focus();
    }
  };
  const toggleCommentRow = () => {
    if(isEmpty(rowGroups) || isCommentRowToggled || !queryParams.commenteeKey) return;

    toggleCommentRowToggled(true);
    setTimeout(() => onClickComment(queryParams.commenteeKey), 1000);
  }

  let filteredRowGroups = rowGroups;
  if(!isEmpty(customPartnerIds)) {
    filteredRowGroups = filteredRowGroups.filter(_ => customPartnerIds.includes(_.customPartner.id));
  }
  if(!isEmpty(accountItemNamesForFilter)) {
    filteredRowGroups = filteredRowGroups.map((rowGroup) => {
      const { rows } = rowGroup;
      return {
        ...rowGroup,
        rows: rows.filter(_ => accountItemNamesForFilter.includes(_.accountItemName)),
      };
    });
  }
  filteredRowGroups = filteredRowGroups.filter(_ => _.rows.length > 0);

  const pagination = usePagination({ countPerPage, totalCount: filteredRowGroups.length, });
  const { pageNumber, offset, first, } = pagination;
  const displayRowGroups = filteredRowGroups.slice(offset, offset + countPerPage);
  const customPartnerOptions = customPartners.map(_ => ({ label: _.name, value: _.id }));
  const toggleTab = (customPartnerType) => {
    history.replace(location.pathname + '?' + qs.stringify({ ...queryParams, customPartnerType, }));
  };
  const rowsForExport = async () => {
    return filteredRowGroups.flatMap(({ customPartner, creditLimit, creditBalance, rows }) => {
      const typesText = customPartner.types?.map(_ => allPartnerTypesById[_].name).join(', ');
      return rows.map((row) => {
        const { accountItemName, openingBalance, debitAmount, creditAmount, closingBalance, noteKey, } = row;
        return mapKeysToJa({
          customPartnerName: customPartner.name,
          types: typesText,
          accountItemName,
          openingBalance,
          debitAmount,
          creditAmount,
          closingBalance,
          creditLimit,
          creditBalance,
          creditRate: creditBalance / creditLimit,
          note: notesByKey[noteKey]?.value,
        });
      });
    });
  };
  const logExport = async () => {
    await log(company, 'partnerBalances', 'exportCsv', user, { period, targetMonth, });
  };
  useEffect(() => {
    const { customPartnerType = 'customer', } = queryParams;
    history.replace(location.pathname + '?' + qs.stringify({ ...queryParams, customPartnerType, }));
  }, []);
  useEffect(() => {
    toggleCommentRow();
  }, [rowGroups]);

  return (
    <div className="company-partner-balances">
      <div className="p-5">
        <div className="d-flex justify-content-end mb-3">
          <HelpLink text="取引先別分析を行う" />
        </div>
        <div className="d-flex justify-content-center mb-2">
          <h3>取引先別分析</h3>
        </div>
        <Nav tabs>
          {
            allPartnerTypes.map((partnerType) => {
              const { id, name } = partnerType;
              return (
                <NavItem key={id}>
                  <NavLink className={classnames('cursor-pointer', { active: customPartnerType === id })} onClick={toggleTab.bind(this, id)}>
                    {name}
                  </NavLink>
                </NavItem>
              )
            })
          }
        </Nav>
        <div className="mt-5 d-flex align-items-end justify-content-between">
          <div className="d-flex gap-1 align-items-end">
            <TrialQueriesSection 
              company={company}
              screenType="partnerBalances"
              items={[
                [
                  {
                    name: 'customPartnerIds',
                    type: 'multiSelect',
                    options: customPartnerOptions,
                    label: 'カスタム取引先',
                    isFront: true,
                    props: { width: 250, },
                  },
                  {
                    name: 'accountItemNamesForFilter',
                    type: 'multiSelect',
                    options: accountItemNameOptions,
                    label: '科目名',
                    isFront: true,
                    props: { width: 300, }
                  },
                ],
              ]}
            />
          </div>
          <div className="d-flex gap-1 align-items-end">
            <Pagination {...pagination} />
            <ExportButton outline fileName="取引先別分析.csv" rows={rowsForExport} onFinish={logExport} />
            <CommentsDropdown company={company} companyId={company.id} currentUser={user} comments={comments.filter(_ => _.yearMonth === targetMonth.toString())} usersById={keyBy(members, 'id')} onClickComment={onClickComment} />
          </div>
        </div>
        <div className="mt-2 d-flex justify-content-start position-relative" style={{ zIndex: 0 }}>
          <table className="table table-hover table-bordered vertical-sticky-table">
            <thead className="thead-light text-center">
              <tr>
                <th style={{ minWidth: 200, }}>
                  カスタム取引先
                </th>
                <th style={{ minWidth: 200, }}>
                  勘定科目
                </th>
                <th>前月残高</th>
                <th>借方金額</th>
                <th>貸方金額</th>
                <th>当月残高</th>
                <th>与信限度額</th>
                <th>与信残高</th>
                <th>与信残高割合</th>
              </tr>
            </thead>
            <tbody>
              {
                displayRowGroups.map((rowGroup) => {
                  const { customPartner, rows, creditLimit, creditBalance, } = rowGroup;
                  return (
                    <RowGroup key={customPartner.id} {...{ commentsGroupedByCommenteeKey, notesByKey, targetMonth, company, user, role, members, customPartner, rows, creditLimit, creditBalance, freeePartnersById, }} />
                  );
                })
              }
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}

function RowGroup (props) {
  const { commentsGroupedByCommenteeKey, notesByKey, targetMonth, company, user, role, members, creditLimit, creditBalance, customPartner, rows, freeePartnersById, } = props;
  const { period, } = useQueryParams();

  return (
    <Fragment>
      {
        rows.map((row, i) => {
          const { accountItemName, openingBalance, debitAmount, creditAmount, closingBalance, commenteeKey, noteKey, } = row;
          const pathParams = {
            period,
            targetMonth,
            complexFilter: [
              ['accountName', '==', accountItemName],
              ['partners', 'in', customPartner.freeePartnerIds?.map(_ => freeePartnersById[_]?.name)],
            ],
            usesArrayFilter: 1,
            complexFilterDescription: `科目: ${accountItemName}, カスタム取引先: ${customPartner.name}`,
          };
          return (
            <tr key={accountItemName}>
              {
                i === 0 && (
                  <td rowSpan={rows.length}>{customPartner.name}</td>
                )
              }
              <td className="has-hovered-contents">
                {accountItemName}
                <HoveredCommenter
                  ref={_ => hoveredCommenters[commenteeKey] = _}
                  company={company}
                  companyId={company.id}
                  currentUser={user}
                  commenteeKey={commenteeKey}
                  queryKey={'partnerBalances'}
                  values={{ yearMonth: formatDate(targetMonth + '01', 'YYYYMM') }}
                  comments={commentsGroupedByCommenteeKey[commenteeKey]}
                  users={members}
                  about={['取引先分析', formatDate(targetMonth + '01', 'YYYYMM'), customPartner.name, accountItemName].filter(_ => _).join(' ')}
                />
                <HoveredNoter companyId={company.id} noteKey={noteKey} pageType="PartnerBalances" queryKey="partnerBalances" values={{ yearMonth: formatDate(targetMonth + '01', 'YYYYMM') }} note={notesByKey[noteKey]} writable={canWriteNote(user, role)} />
              </td>
              <td className="text-right">
                {numeral(openingBalance).format()}
              </td>
              <td className="text-right">
                <Link to={encodeURI(`/companies/${company.id}/journals?${qs.stringify(pathParams)}`)}>
                  {numeral(debitAmount).format()}
                </Link>
              </td>
              <td className="text-right">
                <Link to={encodeURI(`/companies/${company.id}/journals?${qs.stringify(pathParams)}`)}>
                  {numeral(creditAmount).format()}
                </Link>
              </td>
              <td className="text-right">
                {numeral(closingBalance).format()}
              </td>
              {
                i === 0 && (
                  <Fragment>
                    <td rowSpan={rows.length} className="text-right">
                      {numeral(creditLimit).format()}
                    </td>
                    <td rowSpan={rows.length} className="text-right">
                      {numeral(creditBalance).format()}
                    </td>
                    <td rowSpan={rows.length} className="text-right">
                      {numeral(creditBalance / creditLimit).format('0,0.00%')}
                    </td>
                  </Fragment>
                )
              }
            </tr>
          );
        })
      }
    </Fragment>
  );
}
