const { keyBy, isEmpty, sumBy, } = require('lodash');

const { entries } = Object;

const fields = ({ uncontrols, items }) => {
  const flatItems = items.flat();
  const flatItemsByName = keyBy(flatItems, 'name');
  return {
    name: {
      type: 'string',
      label: '名称',
      validations: {
        required: v => !isEmpty(v),
      },
    },
    isControlled: {
      type: 'boolean',
      label: '内部統制レポート',
    },
    extralControls: {
      type: 'multiSelect',
      label: 'Extra',
      options: uncontrols.map((name) => ({ label: flatItemsByName[name]?.label, value: name, })),
    },
  };
};

module.exports = {
  fields,
};
