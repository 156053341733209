import React, { Fragment, useState, useEffect, useRef, useMemo, } from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter, Button, Input, } from 'reactstrap';
import numeral from 'numeral';
import { findKey, fromPairs, mapValues, omit, pickBy, mergeWith, last, chunk, uniqBy, orderBy, uniq, isEqual, get, sumBy, isEmpty, isNumber as _isNumber, groupBy, sortBy, keyBy, range, debounce, pick, } from 'lodash';
import { toast } from 'react-toastify';
import classnames from 'classnames';
import { Container, Draggable } from 'react-smooth-dnd';
import fileDownload from 'js-file-download';
import { unparse as unparseCsv, parse as parseCsv } from 'papaparse';
import { TextEncoder, TextDecoder } from 'text-encoding';
import qs from 'qs';
import Select from 'react-select';
import { useToggle, useDebounce, useMap, } from 'react-use';
import { useLocation, useHistory } from 'react-router-dom';
import { format as formatDate, startOfMonth, endOfMonth, addYears, addMonths, } from 'date-fns';
import arrayMove from 'array-move';

import firebase, { functions } from '../firebase';
import { mapKeysToJa, mapKeysFromJa, jas, } from '../shared/texts';
import { fiscalYearOfPeriod, } from '../utils';
import { fields } from '../shared/models/budgetContainer';
import { batch, getCollectionData, getAllCollectionDataByChunk, } from '../shared/firebase';
import { allDimensions, documentTypes, budgetSubjectTypes, } from '../shared/config';
import useCollectionSubscription from './hooks/useCollectionSubscription';
import useUser from './hooks/useUser';
import ProgressButton from './ProgressButton';
import ModelFormModal from './modals/ModelFormModal';
import AddButton from './AddButton';
import EditButton from './EditButton';
import DeleteButton from './DeleteButton';
import ExportButton from './ExportButton';
import ImportButton from './ImportButton';

const { entries } = Object;
const db = firebase.firestore();
const maxContainersCount = 12;

export default function BudgetButton (props) {
  const { company, period, accountItems, onChangeBudgets, } = props;
  const accountItemNames = accountItems.map(_ => _.name);
  const user = useUser();
  const { start_date: startDate, } = fiscalYearOfPeriod(period, company.fiscalYears);
  const yearMonths = range(0, 12).map(_ => endOfMonth(addMonths(startDate, _)));
  const budgetContainers = useCollectionSubscription(company.ref.collection('budgetContainers').orderBy('index'));
  const customSections = useCollectionSubscription(company.ref.collection('customSections'));
  const customSegment1s = useCollectionSubscription(company.ref.collection('customSegment1s'));
  const customSegment2s = useCollectionSubscription(company.ref.collection('customSegment2s'));
  const customSegment3s = useCollectionSubscription(company.ref.collection('customSegment3s'));
  const budgetSubjectsByName = {
    section: keyBy(customSections, 'name'),
    segment1: keyBy(customSegment1s, 'name'),
    segment2: keyBy(customSegment2s, 'name'),
    segment3: keyBy(customSegment3s, 'name'),
  };
  const budgetSubjectsById = {
    section: keyBy(customSections, 'id'),
    segment1: keyBy(customSegment1s, 'id'),
    segment2: keyBy(customSegment2s, 'id'),
    segment3: keyBy(customSegment3s, 'id'),
  };
  const [isDragging, toggleDragging] = useToggle(false);
  const [isImporting, toggleImporting] = useToggle(false);
  const [showsModal, toggleModal] = useToggle(false);
  const sampleRows = () => {
    return [mapKeysToJa({
      budgetSubjectType: '',
      budgetSubjectName: '',
      accountItemName: '',
      dimensionType: '',
      subItemName: '',
      ...fromPairs(yearMonths.map(_ => ['_' + formatDate(_, 'YYYYMM'), ''])),
    })];
  };
  const onDrop = async ({ addedIndex, removedIndex }) => {
    const newIds = arrayMove(budgetContainers, removedIndex, addedIndex).map(_ => _.id);
    await batch(db, newIds, (batch, id, index) => {
      batch.update(company.ref.collection('budgetContainers').doc(id), { index });
    });
  };
  const budgetSubjectNames = [...customSections, ...customSegment1s, ...customSegment2s, ...customSegment3s].map(_ => _.name);
  const fieldsToImport = {
    budgetSubjectName: {
      validations: {
        exists: _ => budgetSubjectNames.includes(_),
      },
    },
    accountItemName: {
      validations: {
        exists: _ => accountItemNames.includes(_),
      },
    },
  };

  return (
    <Fragment>
      <Button onClick={toggleModal.bind(null, true)}>
        予算
      </Button>
      {
        showsModal && (
          <Modal isOpen style={{ minWidth: 900 }}>
            <ModalHeader>
              予算
            </ModalHeader>
            <ModalBody>
              {
                budgetContainers.length >= maxContainersCount && (
                  <div className="alert alert-warning">
                    予算数の上限に達しています。
                  </div>
                )
              }
              <div className="d-flex justify-content-end align-items-end gap-1">
                <ExportButton fileName="予算フォーマット.csv" label="予算フォーマット" rows={sampleRows} />
                <AddButton itemRef={company.ref.collection('budgetContainers').doc()} FormModal={ModelFormModal} formProps={{ title: '予算 追加', fields: fields(), }} processValues={_ => ({ ..._, index: (last(budgetContainers)?.index ?? 0) + 1 })} disabled={budgetContainers.length >= maxContainersCount} />
              </div>
              <div className="mt-3">
                <table className="table table-hover">
                  <thead className="thead-light">
                    <tr>
                      <th></th>
                      <th style={{ minWidth: 200 }}>予算名</th>
                      <th></th>
                    </tr>
                  </thead>
                  <Container
                    dragHandleSelector=".drag-handle"
                    onDrop={onDrop}
                    onDragStart={_ => toggleDragging(true)}
                    onDragEnd={_ => toggleDragging(false)}
                    dropPlaceholder={{ style: { background: 'eee', } }}
                    render={(ref) => (
                      <tbody ref={ref}>
                        {
                          budgetContainers.map((budgetContainer) => {
                            const { id, ref, name, } = budgetContainer;
                            const deleteItems = async () => {
                              const budgets = await getAllCollectionDataByChunk(company.ref.collection('budgets').where('budgetContainerId', '==', id));
                              await batch(db, budgets, (b, _) => b.delete(_.ref));
                              await ref.delete();
                              await onChangeBudgets?.();
                            };
                            const rowsForExport = async () => {
                              const budgets = await getCollectionData(company.ref.collection('budgets').where('budgetContainerId', '==', id).where('period', '==', period));
                              const budgetsGroupedBySubjectType = groupBy(budgets, 'budgetSubjectType');
                              return entries(budgetSubjectTypes).flatMap(([budgetSubjectType, { label: budgetSubjectTypeLabel,  }]) => {
                                const budgets = budgetsGroupedBySubjectType[budgetSubjectType] || [];
                                return entries(groupBy(budgets, 'budgetSubjectId')).flatMap(([budgetSubjectId, budgets]) => {
                                  const budgetsGroupedByAccountItemName = groupBy(budgets, 'accountItemName');
                                  return accountItems.flatMap((accountItem) => {
                                    const budgets = budgetsGroupedByAccountItemName[accountItem.name] || [];
                                    const budgetsGroupedByDimension = groupBy(budgets, 'dimension');
                                    return entries(allDimensions).flatMap(([dimension, dimensionLabel]) => {
                                      const budgets = budgetsGroupedByDimension[dimension] || [];
                                      return entries(groupBy(budgets, 'subItemName')).flatMap(([subItemName, budgets]) => {
                                        const budgetsGroupedBySameIndex = groupBy(budgets, _ => _.sameIndex || 0);
                                        return entries(budgetsGroupedBySameIndex).flatMap(([sameIndex, budgets]) => {
                                          const budgetsByYearMonth = keyBy(budgets, _ => _.closingDate.replace('-', '').slice(0, 6));
                                          return mapKeysToJa({
                                            budgetSubjectType: budgetSubjectTypeLabel,
                                            budgetSubjectName: budgetSubjectsById[budgetSubjectType][budgetSubjectId]?.name,
                                            accountItemName: accountItem.name,
                                            dimensionType: dimensionLabel,
                                            subItemName,
                                            ...fromPairs(yearMonths.map(_ => ['_' + formatDate(_, 'YYYYMM'), budgetsByYearMonth[formatDate(_, 'YYYYMM')]?.amount])),
                                          });
                                        });
                                      });
                                    });
                                  });
                                });
                              });
                            };
                            const processRows = (rows) => {
                              const processedRows = rows.map(_ => mapKeysFromJa(_, { 勘定科目: 'accountItemName', 金額: 'amount', })).filter(r => ['budgetSubjectType', 'budgetSubjectName', 'accountItemName'].every(_ => r[_]));
                              return processedRows;
                            }
                            const saveRows = async (processedRows) => {
                              const data = processedRows.flatMap((row) => {
                                const { accountItemName, subItemName } = row;
                                const budgetSubjectType = findKey(budgetSubjectTypes, _ => _.label === row.budgetSubjectType);
                                const budgetSubject = budgetSubjectsByName[budgetSubjectType][row.budgetSubjectName];
                                const dimension = findKey(allDimensions, _ => _ === row.dimensionType) || 'none';
                                if(dimension === 'none' && !isEmpty(subItemName)) return;

                                return entries(row).filter(_ => _[0].match(/_\d+/)).map(([yearMonth, amountString]) => {
                                  const itemKey = [accountItemName, 'none'].join('__');
                                  const [, year, month] = yearMonth.match(/_(\d{4})(\d{2})/);
                                  const closingDate = formatDate(endOfMonth(new Date(parseInt(year, 10), parseInt(month - 1, 10))), 'YYYY-MM-DD');
                                  const amount = parseFloat((amountString || '').replace(/,/g, ''), 10) || 0;
                                  const id = [budgetContainer.id, budgetSubjectType, budgetSubject.id, accountItemName.replace(/\//g, '_'), dimension, subItemName.replace(/\//g, '_'), closingDate].join('__');
                                  return {
                                    id,
                                    itemKey,
                                    period,
                                    budgetContainerId: budgetContainer.id,
                                    budgetSubjectType,
                                    budgetSubjectId: budgetSubject.id,
                                    accountItemName,
                                    dimension,
                                    subItemName,
                                    closingDate,
                                    amount,
                                  };
                                });
                              });
                              const indexedData = Object.values(groupBy(data, 'id')).flatMap((data) => {
                                return data.map((row, i) => {
                                  return {
                                    ...row,
                                    sameIndex: i,
                                    id: i === 0 ? row.id : [row.id, i].join('__'),
                                  };
                                });
                              });
                              await batch(db, indexedData, (b, _) => b.set(company.ref.collection('budgets').doc(_.id), omit(_, 'id')));
                              await onChangeBudgets?.();
                            };
                            const saveOldRows = async (processedRows) => {
                              const data = processedRows.flatMap((row) => {
                                const { accountItemName, subItemName, closingDate, } = row;
                                const _closingDate = formatDate(new Date(closingDate), 'YYYY-MM-DD');
                                const amount = parseFloat((row.amount || '').replace(/,/g, ''), 10) || 0;
                                const budgetSubjectType = findKey(budgetSubjectTypes, _ => _.label === row.budgetSubjectType);
                                const budgetSubject = budgetSubjectsByName[budgetSubjectType][row.budgetSubjectName];
                                const dimension = findKey(allDimensions, _ => _ === row.dimensionType) || 'none';
                                if(dimension === 'none' && !isEmpty(subItemName)) return;

                                const itemKey = [accountItemName, 'none'].join('__');
                                const id = [budgetContainer.id, budgetSubjectType, budgetSubject.id, accountItemName.replace(/\//g, '_'), dimension, subItemName.replace(/\//g, '_'), _closingDate].join('__');
                                return {
                                  id,
                                  itemKey,
                                  period,
                                  budgetContainerId: budgetContainer.id,
                                  budgetSubjectType,
                                  budgetSubjectId: budgetSubject.id,
                                  accountItemName,
                                  dimension,
                                  subItemName,
                                  closingDate: _closingDate,
                                  amount,
                                };
                              });
                              const indexedData = Object.values(groupBy(data, 'id')).flatMap((data) => {
                                return data.map((row, i) => {
                                  return {
                                    ...row,
                                    sameIndex: i,
                                    id: i === 0 ? row.id : [row.id, i].join('__'),
                                  };
                                });
                              });
                              await batch(db, indexedData, (b, _) => b.set(company.ref.collection('budgets').doc(_.id), omit(_, 'id')));
                            };
                            const onClickClear = async () => {
                              if(!window.confirm('選択されている年度の予算をクリアします。よろしいですか？')) return;

                              try {
                                const budgets = await getCollectionData(company.ref.collection('budgets').where('budgetContainerId', '==', id).where('period', '==', period));
                                await batch(db, budgets, (b, _) => b.delete(_.ref));
                                await onChangeBudgets?.();
                                toast.success('クリアしました');
                              } catch(e) {
                                console.error(e);
                                toast.error('失敗しました');
                              }
                            };

                            return (
                              <Draggable
                                key={id}
                                render={() => (
                                  <tr style={{ display: !isDragging && 'table-row' }}>
                                    <td style={{ width: 30 }}>
                                      <div className="drag-handle text-muted cursor-pointer">
                                        <span className="fas fa-grip-vertical" />
                                      </div>
                                    </td>
                                    <td>
                                      {name}
                                    </td>
                                    <td className="text-right">
                                      <div className="d-flex gap-1 align-items-center justify-content-end">
                                        <ExportButton label={false} fileName={`${name}.csv`} rows={rowsForExport} />
                                        <ImportButton batchSize={30} label={false} beforeSave={saveRows} processRows={processRows} fields={fieldsToImport} />
                                        {user?.admin && <ImportButton label="旧予算" beforeSave={saveOldRows} processRows={processRows} fields={fieldsToImport} />}
                                        <ProgressButton process={onClickClear} color="danger" outline>
                                          <span className="fas fa-times" />
                                        </ProgressButton>
                                        <EditButton itemRef={ref} FormModal={ModelFormModal} formProps={{ title: '予算 編集', fields, }} />
                                        <DeleteButton deleteItems={deleteItems} />
                                      </div>
                                    </td>
                                  </tr>
                                )}
                              />
                            );
                          })
                        }
                      </tbody>
                    )}
                  />
                </table>
              </div>
            </ModalBody>
            <ModalFooter>
              <Button className="cancel" color="secondary" onClick={toggleModal.bind(null, false)}>閉じる</Button>
            </ModalFooter>
          </Modal>
        )
      }
    </Fragment>
  );
}
